@import "../../assets/style/variables";

.popup-login {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-login__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  .popup-login__wrapper {
    padding: 2rem 1rem;
    width: 100%;
    max-width: 35.25rem;
    background: $white-color;
    box-shadow: 0 1.1170625rem 1.822575rem $blue-color-4;
    border-radius: 0.4703425rem;
    position: relative;

    @media (min-width: $IsTablet) {
      padding: 2.125rem 5.0625rem 3.1875rem 5.0625rem;
    }

    h2 {
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.75rem;
      text-align: center;
      margin-bottom: 2.6875rem;
    }

    .error {
      border-color: red;
      background: red;
      transition: 0.3s;

      &:hover {
        background: $white-color;
        color: red;
      }
    }

    label {
      position: relative;
      margin-bottom: 1rem;
      display: block;

      span {
        position: absolute;
        color: red;
        left: 0;
        top: calc(100% - 0.1875rem);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    form {
      input {
        background: $white-color;
        border: 0.08125rem solid #BDBDBD;
        border-radius: 0.25rem;
        width: 100%;
        padding: 0.6875rem 0 0.6875rem 2rem;
        height: 3.125rem;

        &::placeholder {
          color: rgba(0, 0, 0, 0.61);
        }
      }

      button {
        background: $green-color-0;
        border-radius: 4px;
        padding: 0.9375rem;
        font-weight: 700;
        font-size: 1.1875rem;
        line-height: 1.125rem;
        border: 0.0625rem solid $green-color-0;
        color: $white-color;
        text-align: center;
        width: 100%;
        height: 3.125rem;
        transition: 0.3s;
        position: relative;

        &:hover {
          background: $white-color;
          color: $green-color-0;
        }

        svg {
          position: absolute;
          width: 0.90625rem;
          height: 0.9375rem;
          right: 5rem;
          top: 50%;
          transform: translateY(-50%);

          path {
            fill: currentColor;
          }
        }
      }
    }

    .social {
      margin-bottom: 1.4375rem;

      button,
      a {
        background: $white-color;
        border: 0.08125rem solid #BDBDBD;
        border-radius: 0.25rem;
        width: 100%;
        padding: 0.6875rem 0.5rem 0.6875rem 0.5rem;
        margin-bottom: 0.5625rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 1.0625rem;
        line-height: 1.125rem;
        position: relative;

        svg {
          position: absolute;
          left: 0.9375rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .link {
      font-weight: 600;
      color: #262626;
      align-items: center;
      margin-top: 0.1875rem;
      display: flex;
      width: fit-content;
      margin-left: auto;
      height: 1.75rem;
      margin-bottom: 1.125rem;
    }

    p {
      display: flex;
      align-items: center;
      color: #262626;
      font-weight: 600;
      justify-content: center;

      a,button  {
        text-decoration-line: underline;
        display: inline;
        color: $blue-color-2;
        margin-left: 0.625rem;
      }
    }
  }
}