@import "../../assets/style/variables";

.popup-consultation {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .viber {
    width: 10.125rem;
    max-width: 10.125rem;
    margin-top: 0.9375rem;
    display: block;
    height: 2.625rem;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.2) translateX(0.8125rem);
    }
  }

  &__inner {
    background: $white-color;
    box-shadow: 0 1.1170625rem 1.822575rem $blue-color-4;
    border-radius: 7.52548px;
    padding: 2rem 2.375rem;
    max-width: 35.25rem;
    margin: auto;
    position: relative;

    h2 {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 109.52%;
      margin-bottom: 0.5rem;
    }
  }

  .popup-consultation__btn {
    border: 0.0524103125rem solid $dark-color-5;
    border-radius: 0.3668725rem;
    display: flex;
    align-items: center;
    padding: 0.366874375rem 1.04820625rem;
    font-weight: 700;
    font-size: 0.9433875rem;
    line-height: 97%;
    color: $dark-color-3;
    width: fit-content;
    margin-bottom: 1.625rem;

    span {
      padding: 0.15723125rem 0.314461875rem;
      background: #31CEC5;
      border-radius: 0.15723125rem;
      font-weight: 600;
      font-size: 0.73374375rem;
      line-height: 97%;
      color: $white-color;
      margin-left: 0.625rem;
    }
  }

  .popup-consultation__wrapper {
    display: flex;
    align-items: center;
    padding-left: 1.0625rem;
    margin-bottom: 1.4375rem;

    img {
      max-width: 8.0625rem;
      margin-right: 1.1875rem;
    }

    p {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 128%;
      color: $dark-color-3;
    }
  }

  .popup-consultation__close {
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: $IsTablet) {
      top: 1.5rem;
      right:  1.5rem;
    }

    & svg {
      transition: 0.3s;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
}