@import "../../assets/style/container.module";

.navigation-bg {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
}

.navigation-popup {
  position: absolute;
  top: 8.75rem;
  z-index: 102;
  width: 100%;

  @media (min-width: $IsTablet) {
    position: absolute;
    top: 11.875rem;
  }

  @media (min-width: 1300px) {
    top: 8.75rem;
  }

  .wrapper {
    display: flex;
    background: $white-color;
    border-radius: 8px;
    min-height: 70vh;
    box-shadow: 0 -0.0625rem 3.4375rem rgba(0, 0, 0, 0.07);
  }

  .nav {
    box-shadow: 0 -0.0625rem 3.4375rem rgba(0, 0, 0, 0.07);
    padding: 1.75rem 0;

    @media (min-width: $IsTablet) {
      width: 17.75rem;
      padding: 1.5rem 0.5rem;
      display: flex;
      flex-direction: column;
    }

    @media (min-width: $IsDesktop) {
      padding: 2.5rem 1.53125rem;
      width: 19.75rem;
    }

    li {
      a,button {
        font-weight: 700;
        font-size: 1rem;
        line-height: 116%;
        color: $gray-color-10;
        padding: 0.625rem 1rem;
        transition: 0.3s;
        border-radius: 6px;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        text-align: left;

        @media (min-width: $IsTablet) {
          display: block;
        }

        &:hover {
          background: $gray-color-11;
          color: $blue-color-0;
        }

        &.active {
          background: $gray-color-11;
          color: $blue-color-0;
        }
      }

      &:first-child a,
      &:first-child button {
        display: flex;
        align-items: center;

        svg {
          margin-left: 0.625rem;
        }
      }

      &:last-child {
        a,button {
          margin-bottom: 0;
        }
      }
    }
  }

  .dropdown {
    max-width: 62.0625rem;
    width: 100%;
    height: 37.5rem;
    overflow: scroll;

    .my-masonry-grid {
      background: $gray-color-12;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      padding: 1.5rem 0.5rem;
      border-radius: 8px;

      @media (min-width: $IsDesktop) {
        padding: 2.0625rem 3.8125rem;
      }
    }

    ul {
      max-width: 13.0625rem;
      width: 100%;
      margin-bottom: 2rem;

      li {
        margin-bottom: 0.625rem;
        padding-right: 1.25rem;
      }

      h4 {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 116%;
        color: $dark-color-1;
        margin-bottom: 1rem;
      }

      .link {
        cursor: pointer;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 97%;
        color: $dark-color-3;
        transition: 0.3s;

        &:hover {
          color: $blue-color-0
        }
      }
    }
  }
}

.mobile {
  background: $white-color;
  min-height: 500px;

  .child_item.child_item {
    display: flex;
    align-items: center;

    h2,a,span {
      margin: 0;
      padding: 0.875rem 0;
      margin-right: auto;
    }
  }

  .nav {
    overflow: hidden;

    a,button {
      &:focus {
        background: $gray-color-11;
        color: $blue-color-0;
      }
      svg {
        margin-left: auto;
      }
    }

    .all_category {
      padding: 1rem 0;
      border-bottom: 0.0625rem solid $dark-color-5;
      display: flex;
      align-items: center;

      svg {
        transform: rotate(-180deg);
        width: 0.75rem;
        min-width: 0.75rem !important;
        height: 0.375rem;

        path {
          stroke: currentColor;
        }
      }

      button {
        font-family: $font-inter;
        font-style: normal;
        font-weight: 600;
        color: $dark-color-1;
        font-size: 1rem;
        line-height: 149.52%;
        padding: 0;
        margin: 0 0 0 1rem;
      }
    }
  }
}

.overflow {
  overflow: hidden;
}

.child_title,
.last_child_list {
  position: absolute;
  background: $white-color;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0.75rem;
  z-index: 10;

  h2,
  a,span {
    font-weight: 700;
    font-size: 1rem;
    line-height: 116%;
    color: $gray-color-10;
    margin-bottom: 1rem;
  }
}