@import "../../assets/style/variables";

.loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $white-color;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.0625rem;
    text-align: center;
    position: absolute;
    bottom: 9.75rem;
    left: 50%;
    transform: translateX(-50%);
  }

  svg {
    rect {
      animation: name-of-animation 2.3s ease-out infinite;
    }
  }
}

@keyframes name-of-animation {
  0%    {  x: -30%; }
  100%  { x: 90%; }
}
