@import "../../assets/style/variables";
@import "../../assets/style/container.module";

.button {
  position: relative;
  z-index: 1;
  padding: 0.75rem 1.5rem;
  border: 0.0625rem solid $dark-color-4;
  font-weight: 700;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 149.52%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  transition: 0.3s;

  &.disabled {
    pointer-events: auto !important;
    opacity: 0.5;

    &:hover {
        cursor: not-allowed !important;
    }
  }

  &:hover {
    background: $green-color-0;
    color: $white-color;
    border:  0.0625rem solid $green-color-0;
  }
}