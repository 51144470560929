@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Light.woff") format("woff"),
       url("../fonts/ProximaNova/ProximaNova-Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Regular.woff") format("woff"),
       url("../fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff"),
  url("../fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Bold.woff") format("woff"),
  url("../fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
}


@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Extrabld.woff") format("woff"),
  url("../fonts/ProximaNova/ProximaNova-Extrabld.ttf") format("truetype");
  font-display: swap;
  font-weight: 800;
}


@font-face {
  font-family: "Inter";
  src:  url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src:  url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src:  url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src:  url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
}