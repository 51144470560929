$dark-color-0: #1E1E2D;
$dark-color-1: #000000;
$dark-color-2: #232323;
$dark-color-3:  rgba(0, 0, 0, 0.7);
$dark-color-4: rgba(0, 0, 0, 0.22);
$dark-color-5: rgba(0, 0, 0, 0.1);
$dark-color-6: rgba(0, 0, 0, 0.2);
$dark-color-7:  rgba(0, 0, 0, 1);
$dark-color-8: rgba(0, 0, 0, 0.8);
$dark-color-9: #0A0A0A;

$white-color: #FFFFFF;
$white-color-1: rgba(255, 255, 255, 0.8);
$white-color-2: rgba(255, 255, 255, 1);
$white-color-3: #FAFAFA;
$white-color-4: rgba(0, 160, 227, 0.02);

$gray-color-0: rgba(0, 0, 0, 0.18);
$gray-color-1: rgba(0, 0, 0, 0.22);
$gray-color-2: #5C737D;
$gray-color-3: #898C8E;
$gray-color-4: rgba(62, 81, 118, 0.19);
$gray-color-5: rgba(255, 255, 255, 0.25);
$gray-color-6: rgba(167, 167, 167, 0.5);
$gray-color-7: rgba(255, 255, 255, 0.5);
$gray-color-8: #3F4045;
$gray-color-9: rgba(255, 255, 255, 0.4);
$gray-color-10: #66697E;
$gray-color-11: #F3F6F8;
$gray-color-12: #fcfcfc;
$gray-color-13: #F0F0F0;
$gray-color-14: #F9FFDF;
$gray-color-15: #DDEAEA;
$gray-color-16: #F5F5F5;
$gray-color-17: #666666;
$gray-color-18: rgba(0, 0, 0, 0.3);
$gray-color-19: #FDFDFD;
$gray-color-20: #F4F4F4;
$gray-color-21: rgba(158, 158, 158, 0.58);
$gray-color-22: rgba(0, 0, 0, 0.6);
$gray-color-23: #F4F7F9;
$gray-color-24: #8A8A8A;
$gray-color-25: rgba(0, 0, 0, 0.5);
$gray-color-26: rgba(0, 0, 0, 0.05);
$gray-color-27: #DDDDDD;
$gray-color-28: rgba(0, 0, 0, 0.26);

$brown-color: #A6866B;
$brown-color-1: #E5E7CF;
$brown-color-2: #E4E7CF;

$green-color-0: #50CD89;
$green-color-1: #47CB44;
$green-color-2: #349F65;
$green-color-3: #57BF6E;
$green-color-4: #F3FAF5;
$green-color-5: #37B972;

$blue-color-0: #009EF7;
$blue-color-1: rgba(0, 158, 247, 0.1);
$blue-color-2: #00A0E3;
$blue-color-3: rgba(0, 160, 227, 1);
$blue-color-4: rgba(0, 0, 0, 0.07);

$pink-color-0: #EA4C89;
$pink-color-1: #FEECF1;
$pink-color-2: #FBDFEA;
$pink-color-3: #F1416C;
$pink-color-4: #E46E9C;

$red-color: #CD2929;

$font-proxima: 'Proxima Nova', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-inter: 'Inter', sans-serif;

$font-size-13: 0.8125rem;
$font-size-14: 0.875rem;
$font-size-15: 0.9375rem;
$font-size-16: 1rem;
$font-size-17: 1.0625rem;
$font-size-18: 1.125rem;
$font-size-21: 1.2875rem;
$font-size-22: 1.375rem;
$font-size-25: 1.5625rem;
$font-size-33: 2.0625rem;
$font-size-36: 2.25rem;

$IsDesktop: 1100px;
$IsTablet: 768px;
$IsDesktopLarge: 1600px;