@import "../../assets/style/variables";

.checkbox {
  position: relative;
  padding-left: 2.5rem;

  .label {
    color: $white-color;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 146%;

    &:before {
      content: '';
      width: 1.625rem;
      height: 1.625rem;
      border: 0.0625rem solid $white-color;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.3s;
    }

    &:after {
      content: '';
      width: 1.625rem;
      height: 1.625rem;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      background-image: url('../../assets/images/check.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.75rem 0.625rem;
      transition: 0.3s;
    }
  }

  .input {
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }

  input:checked + label:after {
    opacity: 1;
  }

  input:checked + label:before {
    background: $pink-color-0;
    border-color: $pink-color-0;
  }
}