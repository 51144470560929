@import "../../assets/style/variables";

.wrapper {
  display: flex;
  border: 0.0625rem solid $dark-color-5;
  border-radius: 7px;
  padding: 0.6875rem 1rem;
  background: $white-color;
  align-items: center;
  margin-top: 4.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: left;

  @media (min-width: $IsTablet) {
    margin: 0;
    margin-top: auto;
  }

  .img {
    position: relative;
    margin-right: 0.8125rem;
    min-width: 3.5rem;
    min-height: 3.5rem;

    svg {
      top: 0;
      right: 0;
      position: absolute;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 116%;
  }

  img {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    display: block;
  }
}