@import "../../assets/style/variables";
@import "../../assets/style/container.module";

.footer {
  background: $dark-color-0;

  @media (min-width: $IsTablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .footer-wrapper {
    padding-top: 1.8125rem;
    padding-bottom: 2.5625rem;

    @media (min-width: $IsTablet) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 5.75rem;
      padding-bottom: 5.875rem;
    }

    nav {
     margin-bottom: 1.9375rem;

      @media (min-width: $IsTablet) {
        margin-right: 0.9375rem;
        margin-bottom: 0;
      }

      h4 {
        font-weight: 700;
        font-size: 0.8125rem;
        line-height: 104%;
        letter-spacing: 0.265em;
        text-transform: uppercase;
        color: $white-color;
        margin-bottom: 1rem;

        @media (min-width: $IsTablet) {
          margin-bottom: 2.0625rem;
          font-size: 0.6875rem;
        }
      }

      li {
        @media (min-width: $IsTablet) {
          margin-bottom: 0.9375rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      a, .offerButton {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 102.5%;
        color: $gray-color-7;
        position: relative;
        transition: 0.3s;

        @media (min-width: $IsTablet) {
          font-size: 1rem;
          color: $white-color-1;
        }

        &:hover {
          color: $blue-color-0;
        }
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;

        @media (min-width: $IsDesktop) {
          padding-right: 3.75rem;
        }
      }
    }
  }

  .social {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    @media (min-width: $IsTablet) {
      margin-top: 1.375rem;
    }

    a {
      width: 2.0625rem;
      height: 2.0625rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.0625rem solid $gray-color-6;
      margin-right: 0.8125rem;
      margin-bottom: 0;
      transition: 0.3s;

      @media (min-width: $IsTablet) {
        margin-bottom: 2rem;
      }

      svg path {
        transition: 0.3s;
      }

      &:hover {
        border: 0.0625rem solid $white-color;

        svg path {
          fill: $white-color;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .logo-wrapper {
    margin-bottom: 2.5rem;

    @media (min-width: $IsTablet) {
      margin-right: 0.9375rem;
      margin-bottom: 1.608125rem;
    }

    img {
      max-width: 9.875rem;
      max-height: 1.9375rem;
      margin-bottom: 1rem;

      @media (min-width: $IsTablet) {
        margin-bottom: 0;
      }
    }

    p {
      font-weight: 400;
      font-size: 1rem;
      color: $white-color-1;
      max-width: 12.1875rem;
      line-height: 1.5rem;

      @media (min-width: $IsTablet) {
        margin-bottom: 1.5rem;
        max-width: 100%;
        line-height: 102.5%;
      }
    }

    a {
      font-size: 1.625rem;
      color: $white-color-2;
      display: flex;

      @media (min-width: $IsTablet) {
        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .footer-subscribe {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1.9375rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;

    @media (min-width: $IsTablet) {
      padding: 2.4375rem 0.625rem;
      border-top: 0.0625rem solid #a6866b30;
    }

    .img-wrapper {
      display: flex;
      align-items: center;
    }

    @media (min-width: $IsTablet) {
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    p {
      font-size: 0.875rem;
      line-height: 146%;
      margin-bottom: 1.5625rem;
      color: $gray-color-9;

      @media (min-width: $IsTablet) {
        margin-bottom: 0;
        margin-right: 4.3125rem;
        color: $white-color-1;
      }
    }

    img {
      margin-right: 1.625rem;
      max-width: 2.5rem;

      &:last-child {
        margin-right: 0;
        max-width: 1.9375rem;
      }
    }
  }
}