@import "variables";

.txt-img-price__btn {
  width: 100%;
  max-width: 100%;
  background: $green-color-3;
  color: $white-color;
  border-color: $green-color-3;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.6875rem;
  margin-bottom: 0.4375rem;

  span {
    line-height: 149.52%;
  }

  &:hover {
    background: $green-color-2;
    border-color:  $green-color-2;
  }

  svg {
    margin-right: 1.3125rem;
    max-width: 1.25rem;
  }
}