@import "../../assets/style/variables";

.popup-registration {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;

  @media (min-width: $IsDesktop) {
    padding: 2.5rem;
  }

  .checkbox {
    margin-bottom: 2rem;

    label {
      padding-left: 2.3125rem;
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.0625rem;
      position: relative;

      &.error_check {
        &:before {
          border-color: red;
        }
      }

      &:before {
        content: '';
        width: 1.625rem;
        height: 1.625rem;
        border: 0.0625rem solid $gray-color-28;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s;
      }

      &:after {
        content: '';
        width: 1.625rem;
        height: 1.625rem;
        position: absolute;
        left: 0;
        top: 50%;
        opacity: 0;
        transform: translateY(-50%);
        background-image: url('../../assets/images/check.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 0.75rem 0.625rem;
        transition: 0.3s;
      }
    }

    input {
      position: absolute;
      z-index: -1;
      visibility: hidden;
    }

    input:checked + label:after {
      opacity: 1;
    }

    input:checked + label:before {
      background: $blue-color-2;
      border-color: $blue-color-2;
    }
  }

  .popup-registration__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  .popup-registration__inner {
    @media (min-width: $IsTablet) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2.5rem;
    }
  }

  .popup-registration__wrapper {
    padding: 2rem 1rem;
    width: 100%;
    background: $white-color;
    box-shadow: 0 1.1170625rem 1.822575rem $blue-color-4;
    border-radius: 0.4703425rem;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 90vh;
    -webkit-overflow-scrolling: touch;

    &.loading {
      overflow: hidden;
    }

    @media (min-width: $IsTablet) {
      padding: 2.125rem 2.5rem 2.75rem 2.5rem;
      max-height: 100%;
    }

    @media (min-width: $IsDesktop) {
      max-width: 55.25rem;
    }

    h2 {
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.75rem;
      text-align: center;
      margin-bottom: 2.6875rem;
    }

    .error {
      border-color: red;
      background: red;
      transition: 0.3s;

      &:hover {
        background: $white-color;
        color: red;
      }
    }

    label {
      position: relative;
      margin-bottom: 1rem;
      display: block;

      span {
        position: absolute;
        color: red;
        left: 0;
        top: calc(100% - 0.1875rem);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    form {
      input {
        background: $white-color;
        border: 0.08125rem solid #BDBDBD;
        border-radius: 0.25rem;
        width: 100%;
        padding: 0.6875rem 0 0.6875rem 2rem;
        height: 3.125rem;

        &::placeholder {
          color: rgba(0, 0, 0, 0.61);
        }
      }

      button {
        background: $green-color-0;
        border-radius: 4px;
        padding: 0.9375rem;
        font-weight: 700;
        font-size: 1.1875rem;
        line-height: 1.125rem;
        border: 0.0625rem solid $green-color-0;
        color: $white-color;
        text-align: center;
        width: 100%;
        height: 3.125rem;
        transition: 0.3s;
        position: relative;
        margin-bottom: 1.8125rem;

        &:hover {
          background: $white-color;
          color: $green-color-0;
        }

        svg {
          position: absolute;
          width: 0.90625rem;
          height: 0.9375rem;
          margin-left: 0.9375rem;
          top: 50%;
          transform: translateY(-50%);

          path {
            fill: currentColor;
          }
        }
      }
    }

    .right__block {
      padding-left: 2.5rem;
      position: relative;
      height: fit-content;

      &:before {
        content: '';
        left: 0;
        background: $dark-color-5;
        height: 100%;
        width: 1px;
        position: absolute;
      }

      h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 117.02%;
        color: $dark-color-1;
        margin-bottom: 2.4375rem;
        text-align: left;
        max-width: 17.3125rem;
      }

      &__inner {
        > div {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2.3125rem;

          div {
            width: 2.375rem;
            height: 2.375rem;
            border: 0.058401625rem dashed $blue-color-2;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.8125rem;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              height: 1.6875rem;
              width: 0.0625rem;
              background: rgba(0, 136, 193, 0.3);
              top: calc(100% + 9px);
            }

            svg {
              min-width: 0.875rem !important;
              height: 0.75rem;

              path {
                fill: $blue-color-2;
              }
            }
          }

          p {
            line-height: 145.02%;
            color: $dark-color-1;
            text-align: left;
            font-weight: 400;
            max-width: 16.0625rem;
            margin-bottom: 0;
          }
        }

        > div:last-child div {
          &:before {
            content: none;
          }
        }
      }
    }

    .social {
      margin-bottom: 1.4375rem;

      button,a  {
        background: $white-color;
        border: 0.08125rem solid #BDBDBD;
        border-radius: 0.25rem;
        width: 100%;
        padding: 0.6875rem 0.5rem 0.6875rem 0.5rem;
        margin-bottom: 0.5625rem;
        height: 3.125rem;
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: 1.0625rem;
        line-height: 1.125rem;
        position: relative;

        svg {
          position: absolute;
          left: 0.9375rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .line {
      margin-bottom: 1.0625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      color: #262626;
      font-weight: 600;
      line-height: 1.75rem;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: calc(50% - 2.5rem);
        background: $dark-color-6;
        height: 0.0625rem;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    p {
      display: flex;
      align-items: center;
      color: #262626;
      font-weight: 600;
      justify-content: center;
      margin-bottom: 0.5625rem;

      a,button  {
        display: inline;
        text-decoration-line: underline;
        color: $blue-color-2;
        margin-left: 0.625rem;
      }
    }
  }
}