@import "../../assets/style/variables";

.popup-delivery {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-delivery__grid {
    display: grid;
    gap: 0.5rem;

    @media (min-width: calc($IsTablet - 100px)) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1.5rem;
    }

    > div {
      background: $white-color;
      box-shadow: 0 17.873px 29.1612px $blue-color-4;
      border-radius: 7.52548px;
    }

    > div:nth-child(1) {
      padding: 1rem;

      @media (min-width: $IsDesktop) {
        padding: 2.75rem 3.375rem;
      }

      h4 {
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 102.52%;
        margin-bottom: 1.375rem;
        text-align: left;
      }

      p {
        line-height: 145.02%;
        text-align: left;
      }
    }

    > div:nth-child(2) {
      padding: 1rem;

      @media (min-width: $IsDesktop) {
        padding: 1.875rem 2rem;
      }

      img {
        max-width: 8rem;
        width: 100%;
        max-height: 3.1875rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-weight: 600;
        line-height: 120.02%;
        text-align: left;
        margin-bottom: 1.25rem;
        padding-left: 1rem;

        span {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }

    > div:nth-child(3) {
      padding: 1rem;

      @media (min-width: $IsDesktop) {
        padding: 1.875rem 3.375rem;
      }

      img {
        max-width: 8.8125rem;
        max-height: 2.3125rem;
        margin-bottom: 1.375rem;
      }

      h3 {
        font-weight: 700;
        line-height: 124.02%;
        margin-bottom: 1.5625rem;
        text-align: left;
      }

      p {
        font-weight: 400;
        text-align: left;
        line-height: 124.02%;
      }
    }

    > div:nth-child(4) {
      padding: 1rem;

      @media (min-width: $IsDesktop) {
        padding: 2.75rem 3.375rem;
      }

      h2 {
        font-weight: 700;
        font-size: 1.375rem;
        margin-bottom: 1.375rem;
        line-height: 102.52%;
        text-align: left;
      }

      h3 {
        font-weight: 700;
        line-height: 124.02%;
        margin-bottom: 1.5625rem;
        text-align: left;
      }

      p {
        font-weight: 400;
        line-height: 124.02%;
        text-align: left;
      }
    }
  }


  .popup-delivery__close {
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: $IsTablet) {
      top: 2.1875rem;
      right: 2.625rem;
    }

    & svg {
      transition: 0.3s;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  .popup-delivery__inner {
    background: $white-color;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    box-shadow: 0 17.873px 29.1612px $blue-color-4;
    border-radius: 7.52548px;
    padding: 2.5rem 0.5rem;
    max-width: 76.75rem;
    width: 100%;
    max-height: 100vh;

    @media (min-width: $IsTablet) {
      max-height: 100%;
      padding: 2rem;
    }

    @media (min-width: $IsDesktop) {
      padding: 2.8125rem  5.875rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 149.52%;
    margin-bottom: 0.4375rem;
    text-align: center;

    @media (min-width: $IsTablet) {
      font-size: 2.25rem;
    }
  }

  p {
    font-size: 0.9375rem;
    line-height: 149.52%;
    text-align: center;
    color: #000000;
    max-width: 32.625rem;
    margin: 0 auto 1.875rem auto;
    text-shadow: 0 17.873px 29.1612px $blue-color-4;
  }
}