.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

//----
.transform-x-enter {
  //transform: translateX(100%);
  opacity: 0;
}

.transform-x-enter-active {
  //transform: translateX(0);
  //transition: transform 300ms ease-in;
  opacity: 1;
  transition: 0.3s;
}

.transform-x-exit {
  //transform: translateX(0);
  opacity: 1;
  transition: 0.3s;
}

.transform-x-exit-active {
  //transform: translateX(100%);
  //transition: transform 300ms ease-in;
  opacity: 0;
  transition: 0.3s;
}

//----

.height-enter {
  max-height: 0;
  transition: 0.5s;
}

.height-enter-active {
  max-height: 1000px;
  transition: 0.5s;
}

.height-exit {
  max-width: 1000px;
  transition: 0.5s;
}

.height-exit-active.height-exit-active {
  max-height: 0;
  padding: 0;
  border: unset;
  margin: 0;
  transition: 0.5s;
}

.transform-enter.transform-enter {
  top: 100%;
}

.transform-enter-active.transform-enter-active {
  top: 25%;
  transition: top 300ms ease-in;
}

.transform-exit.transform-exit {
  top: 25%;
}

.transform-exit-active.transform-exit-active {
  top: 100%;
  transition: top 300ms ease-in;
}

.transform-enter-done.transform-enter-done {
  top: 25%;
}

.height-show-exit-done {
  max-height: 0;
  transition: 0.5s;
}

.height-show-enter-done {
  max-width: 1000px;
  transition: 0.5s;
}