@import "../../assets/style/variables";
@import "../../assets/style/container.module";

.header {
  background: $white-color;
  position: relative;

  .btn_a {
    width: 1.5625rem;
    height: 1.5625rem;
    border: 0.0625rem solid black;
    margin: 0 0.3125rem;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

.header-wrapper {
  padding: 0.9375rem 0;

  @media (min-width: 1300px) {
    display: flex;
    align-items: center;
  }
}

.logo {
  max-width: 9.5rem;
  margin-right: auto;

  @media (min-width: $IsTablet) {
    max-width: 13.6875rem;
  }
}

.w-full {
  width: 100%;
}

.logo-partner {
  display: flex;
  width: 100%;
  align-items: center;

  @media (min-width: $IsTablet) {
    margin-bottom: 1.25rem;
  }

  @media (min-width: 1300px) {
    max-width: 30.5625rem;
    margin-right: 0.3125rem;
    margin-bottom: 0;
  }
}

.container-profile {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

.img-partner-wrapper {
  display: flex;
  align-items: center;

  @media (min-width: 1300px) {
    margin: 0 0.3125rem;
  }


  .img-partner {
    max-width: 5rem;
    width: 100%;
    margin-left: 0.875rem;

    @media (min-width: $IsTablet) {
      max-width: 5.6875rem;
      margin-left: 1.6875rem;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.nav {
 margin-right: auto;

  @media (min-width: 1300px) {
    margin: 0 auto;
  }
}

.list-item {
  display: flex;
  align-items: center;

  > li {
    margin-right: 1.5rem;
    font-weight: 700;
    font-size: $font-size-17;
    color: $dark-color-0;
    line-height: 149.52%;
    transition: 0.3s;

    &:hover {
      color: $blue-color-0;
    }
  }

  li:first-child {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
    }
  }

  li:last-child {
    margin-right: 0;
  }

  .dropdown-children {
    position: absolute;
    width: 14.375rem;
    top: calc(100% - 1.875rem);
    z-index: -1;
    opacity: 0;
    padding-top: 1.0625rem;

    div {
      background: $white-color;
      filter: drop-shadow(0 0.625rem 0.9375rem rgba(0, 0, 0, 0.11));

      li {
        margin: 0;
        display: block;
      }

      .offerButton {
        width: 100%;
        display: flex !important;
      }

      a, .offerButton {
        font-weight: 500;
        font-size: 0.875rem;
        font-family: $font-inter;
        line-height: 149.52%;
        color: $dark-color-0;
        padding: 0.3125rem 1rem;
        display: block;
        transition: 0.3s;

        &:hover {
          background: $gray-color-13;
        }
      }
    }
  }
}

.list-item__children {
  position: relative;

  svg {
    transition: 0.3s;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }

    .dropdown-children {
      z-index: 10;
      animation: toggleDropdown 500ms forwards;
      transition: 0.3s;
    }
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
  margin-left: 1.25rem;
  position: relative;

  .btn-blue {
    font-size: $font-size-17;
    line-height: 149.52%;
    font-weight: 700;
    color: $blue-color-0;
    display: flex;
    margin-left: 1.9375rem;
    align-items: center;
    white-space: nowrap;
    transition: 0.3s;

    svg {
      path {
        transition: 0.3s;
        fill: $blue-color-0;
      }
    }

    span {
      border-bottom: 0.0625rem dashed #009EF7;
      margin-left: 0.5rem;
    }

    &:hover {
      color: $dark-color-0;

      path {
        fill: $dark-color-0;
      }

      span {
        border-bottom: 0.0625rem dashed $dark-color-0;
      }
    }


    &:first-child {
      margin-left: 0;
    }
  }
}

.icon {
  margin-left: 1.5rem;

  img {
    min-width: 1.563rem;
    max-width: 1.563rem;
    max-height: 1.563rem;
    min-height: 1.563rem;
    border-radius: 50%;
  }
}

.profile-wrapper {
  margin-left: 1.9375rem;
  display: flex;
  align-items: center;
}

@keyframes toggleDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: calc(100% - 0.625rem);
  }
}