@import "../../assets/style/variables";

.popup-step {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    padding: 0.625rem 1.875rem;
    background: $blue-color-2;
    max-width: 19.4375rem;
    margin: 0 auto;
    border-color: $blue-color-2;
    transition: all 0.3s;

    &:hover {
      background: $white-color;

      span {
        color: $blue-color-2;
      }
    }

    span {
      font-weight: 700;
      font-size: 1rem;
      white-space: nowrap;
      line-height: 149.52%;
      text-transform: uppercase;
      color: $white-color;
      transition: all 0.3s;
    }
  }

  .popup-step__close {
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: $IsTablet) {
      top: 1.5rem;
      right:  1.5rem;
    }

    & svg {
      transition: 0.3s;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  .popup-step__inner-row {
    padding: 2rem 1rem;

    @media (min-width: $IsTablet) {
      padding: 4.3125rem 2.375rem 0 2.375rem;
    }
  }

  .popup-step__inner {
    background: $white-color;
    box-shadow: 0 1.1170625rem 1.822575rem $blue-color-4;
    border-radius: 7.52548px;
    width: 100%;
    max-width: 35.25rem;
    position: relative;

    h4 {
      font-weight: 700;
      font-size: 1.29344375rem;
      line-height: 102.52%;
      max-width: 18.5rem;
    }

    p {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 97%;
      margin-top: 0.4375rem;
    }

    .popup-step__down {
      background: $gray-color-23;
      box-shadow: 0 1.1170625rem 1.822575rem $blue-color-4;
      border-radius: 0.4703425rem;
      padding: 1.9375rem 0 2.25rem 0;

      h4 {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 102.52%;
        text-align: center;
        margin: 0 auto 1.5rem auto;
      }
    }

    .popup-step__row {
      display: flex;
      align-items: flex-start;
      margin-bottom: 3.4375rem;

      &:last-of-type {
        margin-bottom: 0;

        @media (min-width: $IsTablet) {
          margin-bottom: 3.1875rem;
        }

        .span {
          &:before {
            content: none;
          }
        }
      }
    }

    .span {
      margin-right: 2.375rem;
      background: $white-color;
      border: 0.0753075rem dashed $blue-color-2;
      border-radius: 100%;
      width: 3.0625rem;
      height: 3.0625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 1.65524375rem;
      line-height: 102.02%;
      color: $blue-color-2;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        background: rgba(0, 136, 193, 0.3);
        height: 0.0625rem;
        width: 3.5rem;
        top: calc(100% + 1.75rem);
        transform: rotate(90deg);
      }
    }
  }
}