@import "../../assets/style/variables";


.popup-cart {
  background: #FFFFFF;
  box-shadow: 0 1.1875rem 3.625rem rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 1.5rem 0.5625rem;
  position: absolute;
  top: 132%;
  width: 100%;
  right: 0;
  z-index: 100;
  max-width: 25.125rem;

  @media (min-width: $IsTablet) {
    max-width: 27.4375rem;
  }

  &.fixed {
    position: fixed;
    right: 0.625rem;
    top: 1.25rem;

    @media(min-width: $IsTablet) {
      right: 0.625rem;
      max-width: 27.4375rem;
      top: 1.25rem;
    }

    @media(min-width: $IsDesktop) {
      right: 4rem;
    }
  }

  .popup-cart__title {
    padding: 0 1rem 0.625rem 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-color-26;
    margin-bottom: 0.5625rem;

    .popup-cart__svg {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $gray-color-23;
      border: 1px solid $dark-color-5;
      border-radius: 100%;
      width: 2.9375rem;
      height: 2.9375rem;
      margin-right: 1.5rem;
      transition: all 0.3s;

      &.border {
        border-color: $dark-color-1;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 149.52%;
      margin-right: auto;
    }

    div,button {
      display: flex;
      align-items: center;

      svg {
        margin: auto;
      }
    }
  }

  .disable {
    opacity: 0.5;
    pointer-events: none;
  }

  .popup-cart__card {
    margin-bottom: 1.5625rem;
    max-height: 16.4375rem;
    overflow-x: hidden;
    overflow-y: scroll;

    > div {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.0625rem ;
      background: $white-color;
      margin-bottom: 0.25rem;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0.4375rem 1.6875rem rgba(0, 0, 0, 0.09);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      > div {
        max-width: 14.6875rem;
        margin-right: auto;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }

    img {
      width: 100%;
      max-width: 4.125rem;
      max-height: 4.125rem;
      padding: 0.3125rem;
      height: 100%;
      border: 0.0625rem solid $dark-color-5;
      margin-right: 1rem;
      object-fit: contain;
    }

    h4 {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 119.02%;
      margin-bottom: 0.5625rem;
    }

    p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 124.52%;
      color: $gray-color-22;
    }
  }

  .popup-cart__button {
    padding: 0 1.125rem;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      span:first-of-type {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 149.52%;
      }

      span:last-of-type {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 149.52%;
      }
    }

    a {
      background: $green-color-0;
      padding: 1rem;
      display: block;
      font-weight: 600;
      font-size: 1.125rem;
      max-width: 100%;
      color: $white-color;
      text-align: center;
      border-color: $green-color-0;
      line-height: 149.52%;
      width: 100%;
      border-radius: 4px;
      text-transform: inherit;

      &:hover {
        background: $green-color-2;
        border-color: $green-color-2;
      }
    }
  }
}