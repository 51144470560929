@import "fonts";
@import "base.module";
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/css/lazy';
@import 'react-input-range/lib/css/index.css';
@import 'swiper/scss';
@import "container.module";
@import "reset";
@import "swiper";
@import "ButtonGlobal";
@import "ReactTransitionAnimation";

html {
  scroll-padding-top: 10px;
}

div.--active {
  background: $green-color-3;
  transition: 0.3s;

  .txt-img-price__btn {
    border-color: $white-color;
  }

  span {
    color: $white-color !important;
  }
}

.--defects {
  * {
    color: black !important;
    fill: black !important;
    border-color: black !important;
    background: white !important;

    img,svg {
      filter: grayscale(100%) !important;
      -webkit-filter: grayscale(100%) !important;
      background: transparent !important;
      border: unset !important;
    }

    &:before,
    &:after {
      border-color: black !important;
      background: currentColor !important;
    }
  }

  .Tabs_tabs__1kRTM .Tabs_swiper-slide__qM\+nA:after {
    background: white;
  }

  .--border {
    border: 1px solid black !important;
  }

  .--transparent,
  .swiper-pagination, {
    background: transparent !important;

    &:before {
      background: transparent !important;
    }
  }

  .online {
    label:before {
      background: currentColor !important;
    }
  }

  .popup {
    background: rgba(0, 0, 0, 0.15) !important;
  }

  .MuiSlider-thumb.MuiSlider-thumbColorPrimary.PrivateValueLabel-thumb-1,
  .MuiSlider-track.MuiSlider-track, .MuiSlider-rail.MuiSlider-rail {
    background: black !important;
  }

  .MuiSlider-thumb::after {
    background: transparent !important;
  }

  .--tabs {
    .TabCheckout_active__jetot {
      label:after {
        background: black !important;
      }
    }
  }

  .TabCheckout_tab-checkout__pnZnh div.TabCheckout_active__jetot label:after {
    background: white !important;
  }

  .CardsCheckbox_cards-checkbox__xHkIs input:checked + label:after {
    background: white !important;
  }

  .Input_inputWrapper__faV4N .Input_phoneNumberCode__DgRfO {
    background: transparent !important;
    border: unset !important;
    color: unset !important;
  }

  .DropdownText_dropdown-text__JPzvQ .DropdownText_bg__qqJH8 {
    background: linear-gradient(180deg, #FFFFFF 22.7%, rgba(255, 255, 255, 0) 50%) !important;
  }

  .AvailabilityCard_availability-card__Q2pS8.--active {
    background: black !important;
    color: white !important;

    & * {
      background: black !important;
      color: white !important;
      border-color: white !important;
      fill: white !important;
    }
  }

  .AccountSwiper_account-swiper__S2eWi h2 {
    border: unset !important;
  }

  .TabsList_tabs-list__Y0azT {
    background: transparent !important;
    overflow: hidden;
  }

  .TabsList_active__tuMPR {
    background: black !important;
    color: white !important;
  }

  .Card_add__pVKig {
    background: white !important;
    z-index: 10;
  }

  .map_map__y13nl * {
    background: transparent !important;
    color: $white-color !important;

    .leaflet-bar a span {
      color: $dark-color-0 !important;
    }

    .marker-cluster-small.marker-cluster-small div,
    .marker-cluster-medium.marker-cluster-medium div {
      background: #838383 !important;
      color: $white-color !important;
    }
  }
}

.marker-cluster-small.marker-cluster-small div,
.marker-cluster-medium.marker-cluster-medium div {
  background: $blue-color-0;
  color: $white-color;
}

.marker-cluster-small.marker-cluster-small,
.marker-cluster-medium.marker-cluster-medium {
  background: rgb(91 166 220 / 60%);
}

.map_map__y13nl.leaflet-container.leaflet-touch.leaflet-retina.leaflet-safari.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  background: gray !important;
}

.wys {
  padding: 5rem 0;

  * {
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: underline;
    text-underline: black;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2.5rem 0;
    text-align: center;

    @media (min-width: 640px) {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0;
    text-align: center;

    @media (min-width: 640px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  ul,ol {
    list-style: disc;

    li {
      list-style: disc;
      display: list-item;
      margin-left: 0.9375rem;
    }
  }
}