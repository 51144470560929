@import "../../assets/style/variables";

.popup-booking {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);

  @media (min-width: $IsTablet) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: $IsTablet) {
      top: 1.5rem;
      right:  1.5rem;
    }

    & svg {
      transition: 0.3s;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  &__inner {
    max-width: 54rem;
    width: 100%;
    position: relative;
    border-radius: 0.47rem;
    background: $white-color;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100vh;

    @media (min-width: $IsTablet) {
      min-height: unset;
      max-height: unset;
    }
  }

  &__center {
    padding: 1rem;

    @media (min-width: $IsTablet) {
      display: flex;
      align-items: flex-start;
      padding: 1.75rem 1.8125rem;
    }

    img {
      width: 12.5rem;
      margin: 0 auto  0.625rem auto;
      display: flex;

      @media (min-width: $IsTablet) {
        margin: 0 3.125rem 0 0;
        display: block;
      }
    }

    .description {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 107.52%;
      color: rgba(0, 0, 0, 0.56);
      max-width: 25.4375rem;
    }

    .popup-booking__form {
      margin-bottom: 1.8125rem;

      @media (min-width: $IsTablet) {
        display: flex;
        align-items: self-end;
      }

      button {
        padding: 0.5rem 1.25rem;
        font-weight: 700;
        color: $white-color;
        line-height: 149.52%;
        text-transform: uppercase;
        background: $green-color-3;
        border-radius: 8px;
        border: 0.0625rem solid $green-color-3;
        transition: 0.3s;
        width: 100%;
        height: 100%;

        @media (min-width: $IsTablet) {
          width: 10.0625rem;
        }

        &:hover {
          background: $white-color;
          color: $green-color-3;
          border-color: $green-color-3;
        }
      }

      label {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: $gray-color-22;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 0.625rem;

        @media (min-width: $IsTablet) {
          width: calc(100% - 10.0625rem);
          margin-right: 0.6875rem;
          margin-bottom: 0;
        }

        input {
          height: 2.625rem;
          width: 100%;
          background: $white-color;
          border: 0.0625rem solid rgba(0, 0, 0, 0.24);
          border-radius: 4px;
          padding: 0.9375rem 1.25rem;
        }
      }
    }

    .popup-booking__wrapper {
      @media (min-width: $IsTablet) {
        width: calc(100% - 12.5rem);
      }

      .price {
        font-weight: 700;
        font-size: 2.238375rem;
        line-height: 2.6875rem;
      }

      .popup-booking__btn-wrapper {
        margin-bottom: 0.9375rem;

        @media (min-width: $IsTablet) {
          margin-right: 3.75rem;
          margin-bottom: 0;
        }

        h4 {
          margin-bottom: 0.125rem;
          height: 1.8125rem;
        }

        &:last-child {
          margin-bottom: 0;
          margin-right: 0;
        }
      }

      .count {
        font-weight: 400;
        line-height: 1.5rem;
        color: $gray-color-22;
        margin-bottom: 0.625rem;
        height: 1.5rem;
        display: flex;
      }

      .popup-booking__btn {
        background: rgba(0, 160, 227, 0.02);
        border: 0.0625rem dashed $blue-color-2;
        border-radius: 0.5rem;
        padding: 1rem 1.5625rem;
        margin-bottom: 1.3125rem;

        @media (min-width: $IsTablet) {
          display: flex;
          align-items: center;
          width: 100%;
        }

        h4 {
          font-weight: 600;
        }
      }

      .popup-booking__button-inner {
        display: flex;
        align-items: center;
        width: 8.6875rem;
        justify-content: space-between;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.3575rem;
          height: 2.3575rem;
          border: 0.067365625rem solid $gray-color-18;
          border-radius: 8px;
          transition: 0.3s;

          &:hover {
            border-color: $blue-color-0;
            color: $blue-color-0;
          }
        }
      }

      .popup-booking__title {
        h2 {
          margin-bottom: 1.5rem;
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 1.6875rem;
        }

        p {
          display: flex;
          align-items: center;
          font-weight: 400;
          line-height: 1.5rem;
          margin-bottom: 0.6875rem;

          span {
            color:  $blue-color-2;
            margin-left: 0.1875rem;
          }

          svg {
            margin-right: 0.5625rem;
            width: 1.0625rem;
            min-width: 1.0625rem !important;
          }
        }
      }
    }
  }

  &__top {
    padding: 1rem;
    border-bottom: 1px solid $dark-color-5;

    @media (min-width: $IsTablet) {
      padding: 1.25rem 3.75rem;
      display: flex;
      align-items: center;
    }

    img {
      width: 5.5625rem;
      margin-right: 3.5rem;
    }

    h4 {
      font-weight: 700;
      line-height: 1.125rem;
      margin-bottom: 0.25rem;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.5rem;

      a {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        text-decoration-line: underline;
        color: $blue-color-2;
        margin-left: 0.875rem;
      }
    }
  }
}