@import "../../assets/style/variables";

.change-password {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .change-password__close {
    position: absolute;
    right: 1.375rem;
    top: 1.375rem;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.2);
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.6875rem;
    border-bottom: 0.0625rem solid $gray-color-26;
    line-height: 114.52%;
    padding: 1.5rem 0.75rem 1rem 0.75rem;

    @media (min-width: $IsTablet) {
      padding: 1.5625rem 2rem 1.125rem 2rem;
    }
  }

  .change-password__btn-inner {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    gap: 1.3125rem;
  }

  .change-password__label {
    padding: 1rem 0.5625rem 1.5rem 0.5625rem;

    @media (min-width: $IsTablet) {
      padding: 1.125rem 2rem;
    }

    fieldset {
      margin-bottom: 2.8125rem;

      label {
        color: $dark-color-1;
        position: static;

        &:before {
          border-color: #CFCFCF;
        }
      }

      input:checked + label:before {
        background: $blue-color-0;
        border-color: $blue-color-0;
      }
    }
  }

  span {
    color: red;
  }

  .change-password__inner {
    background: $white-color;
    box-shadow: 0 -0.0625rem 3.4375rem $blue-color-4;
    border-radius: 8px;
    max-width: 31.75rem;
    width: 100%;
    position: relative;

    label {
      margin-bottom: 1rem;
      position: relative;

      button {
        position: absolute;
        bottom: 0.9rem;
        display: flex;
        right: 1rem;

        &.active {
          svg path {
           opacity: 1;
          }
        }
      }
    }

    .password {
      font-weight: 700;
      line-height: 1.5rem;
      color: $blue-color-0;
      margin-bottom: 1rem;
    }
  }

  .btn {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 149.52%;
    padding: 0.5rem 0.75rem;
    text-align: center;
    color: $blue-color-2;
    height: 2.3125rem;
    border: 0.0625rem solid $blue-color-2;
    border-radius: 4px;
    width: 100%;
    transition: 0.3s;

    &:hover {
      background: $blue-color-2;
      color: $white-color;
    }
  }

  .btn-gray {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 149.52%;
    padding: 0.5rem 0.75rem;
    background: $gray-color-23;
    text-align: center;
    color: $dark-color-1;
    height: 2.3125rem;
    border-radius: 4px;
    width: 100%;
    transition: 0.3s;

    &:hover {
      background: $blue-color-2;
      color: $white-color;
    }
  }
}

span.error {
  color: red;
  position: absolute;
}