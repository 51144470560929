@import "variables";

* {
  padding: 0;
  margin: 0;
  border: none;

  -webkit-tap-highlight-color: transparent;

  :focus {
    outline: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100%;
  left: 0;
  right: 0;
}

a:focus,
a:active {
  /* outline: none;*/
}

/* Links */

a  {
  /* color: inherit; */
  text-decoration: none;
  color: currentColor;
  /* display: inline-block; */
}

a:hover  {
  /* color: inherit; */
  text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
}

svg {
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

.swiper-pagination-bullet {
  background: $white-color;
}

.input-range__label-container {
  display: none;
}

.input-range__track {
 color: #00000012;
}

.input-range__slider {
  background: $blue-color-0;
  border-color: $blue-color-0;
}

.input-range__track--active {
  background: $blue-color-0;
}

.input-range__slider {
  width: 0.9375rem;
  height:  0.9375rem;
}

.input-range {
  width: calc(100% - 0.9375rem);
  margin: auto;
}

.MuiSlider-root.MuiSlider-colorPrimary {
  color: $blue-color-0;
}

.MuiSlider-thumb.MuiSlider-thumbColorPrimary.PrivateValueLabel-thumb-1 {
  height: 0.9375rem;
  width: 0.9375rem;
}

.MuiSlider-track.MuiSlider-track,.MuiSlider-rail.MuiSlider-rail {
  height: 0.3rem;
}

.h-full {
  height: 100%;
}

.h-0 {
  height: 0;
}