@import "../../assets/style/variables";

.location-popup {
  position: absolute;
  top: 132%;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background: $white-color;
  box-shadow: 0 -0.0625rem 3.4375rem $blue-color-4;
  border-radius: 8px;
  padding: 1.5625rem 1.375rem 2.6875rem 1.375rem;
  width: 42.625rem;
  min-height: 15.0625rem;

  .location-popup__inner {
    position: relative;

    .close {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      transform: scale(1.2);

      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 1.6875rem;
      line-height: 114.52%;
      padding-left: 0.625rem;
      margin-bottom: 1.9375rem;
    }
  }

  .location-popup__search {
    margin-top: 1.3125rem;
    position: relative;
    //margin-bottom: 9.8125rem;

    label {
      position: relative;
      background: $white-color-3;
      border: 1px solid #DCDCDC;
      padding-left: 3.1875rem;
      display: flex;
      height: 3.0625rem;
      color: #898C8E;

      button {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;

        svg {
          width: 1.1875rem;
          height: 1.1875rem;

          path {
            fill: currentColor;
          }
        }
      }

      input {
        width: 100%;
        border: unset;
        background: #0000;
        height: 100%;
      }
    }

    ul {
      background: $white-color;
      box-shadow: 0 0.75rem 0.5625rem rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      position: absolute;
      left: 0;
      right: 0;
      top: calc(100% + 0.625rem);
      max-height: 8.5rem;
      overflow-y: auto;

      li {
        button {
          padding: 0.3125rem 0.625rem;
          font-size: 0.9375rem;
          line-height: 204.02%;
          color: $dark-color-1;
          transition: 0.3s;
          width: 100%;
          display: block;
          text-align: left;

          &:hover {
            background: $gray-color-23;
            text-shadow: 0.03125rem 0 0 currentColor;
          }
        }
      }
    }

    input {
      background: #FAFAFA;
      border: 1px solid #DCDCDC;
      border-radius: 4px;
    }
  }

  .location-popup__country {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;

    img {
      width: 100%;
      height: 100%;
    }

    button {
      padding: 0.5rem 0.9375rem;
      font-weight: 700;
      line-height: 149.52%;
      border: 1px solid $dark-color-4;
      border-radius: 4px;
      margin: 0.25rem;
      transition: 0.3s;

      &.active {
        color: $pink-color-4;
        border-color: $pink-color-4;
      }

      &:hover {
        color: $pink-color-4;
        border-color: $pink-color-4;
      }
    }
  }

  .all {
    padding: 0.3125rem 0;
    font-size: 1.2rem;
    font-weight: bold;
    width: fit-content;
    line-height: 204.02%;
    color: $dark-color-1;
    transition: 0.3s;
    display: block;
    text-align: left;
  }
}