@import "../../assets/style/variables";

.drag-drop{
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .drag-drop__close {
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: $IsTablet) {
      top: 1.5rem;
      right:  1.5rem;
    }

    & svg {
      transition: 0.3s;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  &__inner {
    width: 35.25rem;
    border-radius: 0.47034375rem;
    position: relative;
    background: $white-color;
    box-shadow: 0 1.116875rem 1.8225760459899902rem 0 $blue-color-4;
    padding: 2.4375rem 3rem 3.3125rem 3rem;

    & .drag-drop__img {
      margin: 0 auto 1.1875rem auto;
      display: block;
      width: 7.75rem;
    }

    h2,h3 {
      color: $dark-color-1;
      text-align: center;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 117.023%;
      height: 6.3125rem;
    }

    h3 {
      height: 100%;
      color: $green-color-3;
    }

    p {
      color: $dark-color-1;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      margin-bottom: 1.1875rem;
    }

    .button {
      background: $green-color-0;
      border-radius: 4px;
      padding: 0.9375rem;
      font-weight: 700;
      max-width: 25.75rem;
      margin: 0 auto;
      display: block;
      font-size: 1.1875rem;
      line-height: 1.125rem;
      border: 0.0625rem solid $green-color-0;
      color: $white-color;
      text-align: center;
      width: 100%;
      height: 3.125rem;
      transition: 0.3s;
      position: relative;

      &:hover {
        background: $white-color;
        color: $green-color-0;
      }

      svg {
        position: absolute;
        width: 0.90625rem;
        height: 0.9375rem;
        right: 5rem;
        top: 50%;
        transform: translateY(-50%);

        path {
          fill: currentColor;
        }
      }
    }
  }

  .drag-drop__drop {
    border-radius: 0.375rem;
    border: 0.0625rem dashed rgba(255, 30, 73, 0.41);
    background: rgba(255, 30, 73, 0.02);
    padding: 1.8125rem 0 2.1875rem 0;
    display: block;
    margin-bottom: 1.1875rem;
    width: 100%;

    p {
      font-size: 0.8125rem;
      text-align: center;

      span {
        margin: 0 0.1875rem;
      }
    }
  }

  .drop_title {
    font-weight: 600;
    margin-bottom: 0.6875rem;
  }

  .drop_desc {
    margin-bottom: 1.4375rem;

    span {
      color: $pink-color-0;
      font-weight: 600;
    }
  }

  .span {
    color: rgba(66, 70, 79, 0.60);
    font-size: 0.625rem;
    font-weight: 500;
  }
}