@import "../../assets/style/variables";

.personal-data {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100vw;
    left: -16px;
    right: 0;
    height: 1px;
    top: 0;
    background: #0000001a;
  }


  @media (min-width: 820px) {
    position: static;

    &:before {
      content: none;
    }
  }

  .personal-data__inner {
    background: $white-color;
    border-radius: 4px;
    margin-bottom: 1rem;

    .underline {
      line-height: 1.5rem;
      font-weight: 700;
      color: $blue-color-0;
      padding-bottom: 0.0625rem;
      border-bottom: 0.0625rem dashed $blue-color-0;
      transition: 0.3s;

      &:hover {
        border-color: $dark-color-1;
        color: $dark-color-1;
      }

      @media (min-width: $IsTablet) {
        margin-left: 1rem;
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }

    .personal-data__wrapper {
      display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));
      gap: 0.5rem;
      padding: 0.5rem 0;

      @media (min-width: $IsTablet) {
        padding: 1rem;
        display: flex;
        align-items: flex-end;
        gap: 0;
      }

      @media (min-width: $IsDesktop) {
        padding: 1.875rem 1.0625rem;
      }

      button {
        display: none;
        align-items: center;
        justify-content: center;
        transform: translateY(-13px);
        min-width: 1.25rem;

        @media (min-width: $IsTablet) {
          display: flex;
        }
      }

      label {
        margin-right: 0.875rem;

        &:last-of-type {
          margin-right: 1.4375rem;
        }
      }

      .big {
        width: 100%;

        @media (min-width: $IsTablet) {
          max-width: 19.75rem;
        }
      }

      .small {
        width: 100%;

        @media (min-width: $IsTablet) {
          max-width: 7.875rem;
        }
      }
    }

    h4 {
      font-family: $font-inter;
      font-style: normal;
      font-weight: 700;
      border-bottom: 0.0625rem solid $dark-color-5;
      line-height: 149.52%;
      padding: 0.5rem 0;

      @media (min-width: $IsTablet) {
        padding: 1.125rem 1.0625rem 1.0625rem 1.125rem;
      }
    }

    .personal-data__input-wrapper {
      display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));
      gap: 0.5rem;
      width: 100%;
      padding: 0.5rem 0;

      @media (min-width: $IsTablet) {
        padding: 0.625rem 1rem;
        gap: 1.5rem;
      }

      @media (min-width: $IsDesktop) {
        padding: 1.875rem 1.5rem;
      }
    }

    .label {
      font-weight: 400;
      font-size: 0.875rem;
      width: 100%;
      position: relative;
      line-height: 1.5rem;
      display: block;
      color: $gray-color-22;
    }

    .calendar_data {
      position: static;

      @media (min-width: $IsTablet) {
        position: relative;
      }

      > div {
        position : absolute;
        left: 50%;
        transform: translateX(-50%);
        padding:0 0.875rem;
        width: 100%;
        z-index: 10;

        @media (min-width: $IsTablet) {
          left: unset;
          padding-right: 0;
          padding-left: 0;
          transform: unset;
          top: calc(100% + 10px);

        }

        > div {
          margin-left: auto;

          @media (min-width: $IsTablet) {
            margin-left: unset;
          }
        }
      }
    }

    .input__dropdown {
      background: $white-color;
      display: block;
      border: 0.0625remsolid $dark-color-5;
      width: 100%;
      font-weight: 600;
      line-height: 1.5rem;
      color: $dark-color-1;
      max-width: 100%;
      margin-top: 0.5rem;

      > button {
        padding: 0.5625rem 1rem;
        color: $dark-color-1;
      }
    }
  }

  .btn {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 149.52%;
    padding: 0.5rem 0.75rem;
    text-align: center;
    color: $blue-color-2;
    height: 2.3125rem;
    border: 0.0625rem solid $blue-color-2;
    border-radius: 4px;
    transition: 0.3s;

    @media (min-width: $IsTablet) {
      margin-bottom: 1.5rem;
      margin-left: 1.5rem;
    }

    &:hover {
      background: $blue-color-2;
      color: $white-color;
    }
  }

  .personal-data__btn {
    padding: 0.5rem 0;
    background: $white-color;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    @media (min-width: $IsTablet) {
      padding: 1.125rem 1rem;
      flex-wrap: nowrap;
    }

    .btn {
      margin: 0;
    }

    .personal-data__btn-inner {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;

      @media (min-width: $IsTablet) {
        margin-left: auto;
        margin-top: 0;
      }
    }

    .btn-gray {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 149.52%;
      padding: 0.5rem 0.75rem;
      background: $gray-color-23;
      text-align: center;
      color: $dark-color-1;
      height: 2.3125rem;
      border-radius: 4px;
      margin-left: 0.9375rem;
      transition: 0.3s;

      &:hover {
        background: $blue-color-2;
        color: $white-color;
      }
    }

    fieldset label {
      color: $dark-color-1;
      font-size: 1rem;
      font-weight: 600;

      &:before {
        border-color: $dark-color-6;
      }
    }

    input:checked + label {
      &:before {
        background: $blue-color-2;
        border-color: $blue-color-2;
      }
    }
  }
}

.label {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: block;
  color: $gray-color-22;
}

.input {
  background: $white-color;
  border: 0.0625rem solid $dark-color-5;
  padding: 0.5625rem 1rem;
  height: 2.875rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  width: 100%;
  font-weight: 600;
  line-height: 1.5rem;
  color: $dark-color-1;
  max-width: 100%;

  &::placeholder {
    color: $dark-color-4;
  }
}