@import "../../assets/style/variables";

.list-editing-card {
  position: relative;

  .list-editing-card__price {
    display: flex;
    align-items: center;
    padding: 1.25rem 1.4375rem;
    background: $white-color;
    flex-direction: column;

    @media (min-width: calc($IsTablet - 200px)) {
      flex-direction: row;
      border-top: 0.0625rem solid $dark-color-5;
    }

    a {
      padding: 0.625rem 2.5rem;
      background: $blue-color-0;
      color: $white-color;
      border-color: $blue-color-0;

      svg {
        fill: currentColor;
        margin-right: 0.875rem;

        path {
          fill: currentColor;
        }
      }

      &:hover {
        background: $white-color;
        color: $blue-color-0;
      }
    }

    p {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: $dark-color-3;
      margin-bottom: 0.9375rem;
      display: flex;
      align-items: center;

      @media (min-width: calc($IsTablet - 200px)) {
        margin-right: auto;
        margin-bottom: 0;
      }

      span {
        margin-left: 1.0625rem;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.125rem;
        color: $dark-color-1;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    left: -16px;
    right: 0;
    height: 1px;
    top: 0;
    background: $dark-color-5;
  }

  @media (min-width: calc($IsTablet + 52px)) {
    &:before {
      content: none;
    }
  }

  h3 {
    font-family: $font-inter;
    font-style: normal;
    font-weight: 700;
    line-height: 149.52%;
    margin-bottom: 1rem;
  }

  .list-editing-card__inner {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    row-gap: 1rem;
    column-gap: 2.1875rem;
    white-space: nowrap;
    margin-bottom: 1.5rem;
    padding-top: 1.25rem;


    @media (min-width: calc($IsTablet + 52px)) {
      row-gap: 0;
      column-gap: 0;
      justify-content: space-evenly;
      margin-bottom: 1rem;
      padding-top: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    @media (min-width: $IsDesktop) {
      margin: -0.3125rem 0 1rem -0.3125rem;
      justify-content: flex-start;
    }
  }

  .list-editing-card__card-inner {
    background: $white-color;
    border-radius: 4px;
    width: 100%;

    @media (min-width: calc($IsTablet + 52px)) {
      padding: 0.75rem 0.5rem;
    }

    @media (min-width: $IsDesktop) {
      padding: 1.25rem 1rem;
    }

    button {
      white-space: nowrap;
    }

    .last__btn {
      position: absolute;
      right: 0;
      top: 23px;

      @media (min-width: $IsDesktop) {
        position: static;
      }

      svg {
        min-width: 1.25rem;
      }
    }

    .first__btn {
      text-transform: inherit;
      padding: 0.5rem 0.9375rem;
      max-width: 100%;
      width: 100%;
      margin-top: 1.125rem;

      span {
        transition: 0.3s;
      }

      &:hover span {
        color: $white-color;
      }

      @media (min-width: $IsDesktop) {
        margin-right: 2.5rem;
        max-width: fit-content;
        margin-left: auto;
        margin-top: 0;
      }

      span {
        font-weight: 700;
        font-size: 0.875rem;
        color: $dark-color-0;
        line-height: 149.52%;
      }

      svg {
        min-width: 1rem !important;
        margin-right: 0.75rem;
        max-width: 1rem;

        path {
          fill: currentColor;
        }
      }
    }
  }

  .list-editing-card__desktop-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .list-editing-card__dropdown {
    width: 100%;
    border: 0.0625rem solid $gray-color-26;
    max-width: 100%;
    margin-bottom: 0.875rem;

    @media (min-width: $IsDesktop) {
      max-width: 29.125rem;
      margin: 0.3125rem auto 0.3125rem 0.3125rem;
    }

    > button {
      width: 100%;
      text-align: left;
    }

    svg {
      position: absolute;
      top: 0.875rem;
      z-index: 0;
      right: 1.125rem;
    }

    button {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 116%;
      color: $dark-color-1;
      position: relative;
      z-index: 1;
    }

    li button {
      font-weight: 400;
    }
  }

  .btn {
    font-weight: 600;
    line-height: 0.9375rem;
    color: $blue-color-0;
    display: flex;

    @media (min-width: $IsDesktop) {
      margin: 0.3125rem 2.5rem 0.3125rem 0.3125rem;
    }

    span {
      border-bottom: 0.0625rem dashed $blue-color-0;
      padding-bottom: 0.1875rem;
    }

    svg {
      margin-right: 0.5rem;

      path {
        fill: currentColor;
      }
    }

    &:last-of-type {
      margin-right: 0;

      svg {
        min-width: 1rem !important;
        max-width: 1rem;
      }
    }
  }
}

.list-editing-card__card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.8125rem;
  position: relative;

  @media (min-width: calc($IsTablet + 52px)) {
    padding: 0.5rem;
    border-bottom: 0.0625rem solid $dark-color-5;
  }

  @media (min-width: $IsDesktop) {
    padding: 0.75rem 1.5625rem 0.75rem 0;
    flex-wrap: nowrap;
    position: static;
  }

  .list-editing-card__desktop-wrapper > div {
    margin-right: 0.625rem;
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: unset;
  }

  img {
    max-width: 4.5rem;
    max-height: 4.5rem;
    width: 100%;
    height: 4.8125rem;
    margin-right: 0.5rem;
    object-fit: contain;

    @media (min-width: $IsDesktop) {
      margin-right: 1.625rem;
      max-width: 4.9375rem;
      max-height: 4.8125rem;
    }
  }

  > div {
    h4 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.0625rem;
      color: $dark-color-8;
      margin-bottom: 0.5rem;
      max-width: 14.3125rem;

      @media (min-width: calc($IsTablet + 52px)) {
        max-width: 29.625rem;
      }

      @media (min-width: $IsDesktop) {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }

    p {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.0625rem;

      @media (min-width: $IsDesktop) {
        font-size: 1.25rem;
        line-height: 1.125rem;
      }
    }
  }
}

.list-editing-card__desktop-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}