@import "variables";

.container {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 0.625rem;
  width: 100%;

  @media (min-width: $IsDesktop) {
    padding: 0 4rem;
  }
}