@import "variables";

main {
  min-height: 100vh;
}

html {
  @media (min-width: $IsDesktop) {
    font-size: 1.111vw;
  }

  &.small {
    font-size: 3.5vw;

    @media (min-width: $IsDesktop) {
      font-size: 1.08vw;
    }
  }

  &.base {
    font-size: 3.89vw;

    @media (min-width: $IsDesktop) {
      font-size: 1.111vw;
    }
  }

  &.big {
    font-size: 4.2vw;

    @media (min-width: $IsDesktop) {
      font-size: 1.25vw;
    }
  }
}

.no-data-fetch {
  font-size: 1rem;
  font-weight: 600;

  @media (min-width: $IsDesktop) {
    font-size: 1.875rem;
  }
}

body {
  font-family: $font-proxima;
  font-size: 1rem;
}

.blog {
  .button-items {
    color:red
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
  filter: blur(5px);
}

.lazyloaded {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms;
}