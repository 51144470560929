@import "../../assets/style/variables";
@import "../../assets/style/container.module";

.search {
  background-color: $blue-color-2;
  padding: 0.9375rem 0;

  .mobile_eye {
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  button p {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .mobile_location {
    left: 0;
    right: 0;
    width: 100%;
    padding: 1.5625rem 0.5rem 2.6875rem 0.5rem;

    button.btn {
      right: 0.4375rem;
      transform: scale(1.2);
    }
  }
}

.icon {
  display: flex;

  svg {
    fill: #fff;

    path {
      fill: #fff;
    }
  }
}

button.click p {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: $green-color-0;
  color: $white-color;
  opacity: 1;
  transition: all 0.3s;
  pointer-events: none;
}

.search-wrapper {
  @media (min-width: $IsTablet) {
    display: flex;
    align-items: center;
  }
}

.btn {
  display: flex;
}

.mobile-btn {
  display: flex;
  align-items: center;
  margin-right: 0.9375rem;
  margin-left: 0.6875rem;

  > button {
    display: flex;
    align-items: center;
    margin-right: 0.9375rem;
    font-weight: 700;
    font-size: 1.0625rem;
    line-height: 149.52%;
    color: $white-color;

    &:first-child {
      margin-right: 0.9375rem;
      position: absolute;
      left: 0;
      bottom: -9px;

      @media (min-width: $IsTablet) {
       position: static;
      }

      span {
        border-bottom: 0.0625rem dashed $white-color;
      }

      @media (min-width: 375px) {
        margin-right: 2.25rem;
      }

      svg {
        max-width: 0.8125rem;
      }

      span {
        margin-left: 0.5rem;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    svg path {
      fill: $white-color;
    }
  }
}

.button-catalog {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: $font-size-15;
  line-height: 149.52%;
  text-transform: uppercase;
  padding: 0.75rem 0.8125rem 0.75rem 1rem;
  background: $pink-color-0;
  border-radius: 4px;
  color: $white-color;
  transition: 0.3s;
  height: 2.875rem;
  margin-right: auto;

  @media (min-width: $IsTablet) {
    margin-right: 0.5rem;
    height: 2.9375rem;
    font-size: $font-size-18;
    padding: 0.6875rem 1.375rem 0.6875rem 1rem;
  }

  svg {
    margin-right: 0.5rem;
    transition: 0.3s;

    @media (min-width: $IsTablet) {
      margin-right: 1rem;
    }

    path {
      transition: 0.3s;
    }
  }

  &:hover {
    background: $gray-color-8;
    color: $white-color;

    path {
      opacity: 1;
    }
  }
}

.input-search-popup {
  position: absolute;
  top: calc(100% + 0.125rem);
  left: 0;
  right: 0;
  z-index: 101;

  .input-search-popup__wrapper {
    background: $white-color;
    border-radius: 4px;
    padding: 0.6875rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 375px) {
      min-height: 365px;
    }

    @media (min-width: $IsTablet) {
      padding: 0.6875rem 0.5rem 1.875rem 0.6875rem;
      min-height: 400px;
    }

    @media (min-width: $IsDesktop) {
      min-height: 445px;
    }

    @media (min-width: $IsDesktopLarge) {
      min-height: 495px;
    }

    .empty-list-title {
      text-align: center;
    }

    .input-search-popup__wrapper__card {
      padding: 0 0.5rem;
      height: 100%;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: $IsTablet) {
        padding: 0;
        height: auto;
        overflow: unset;
      }

      > div {
        h4 {
          max-width: 100%;

          @media (min-width: $IsTablet) {
            max-width: 85%;
          }
        }

        a,button {
          max-width: 100%;
          margin-top: 0.9375rem;

          @media (min-width: $IsTablet) {
            max-width: fit-content;
            margin-top: 0;
          }
        }

        @media (min-width: $IsTablet) {
          flex-wrap: nowrap;
        }
      }

      >  div:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    .link {
      width: 100%;
      padding: 0 2.875rem;
      margin-top: 1rem;

      @media (min-width: $IsTablet) {
        margin-top: auto;
      }

      > a {
        padding: 0.5rem 0.9375rem;
        border: 1px solid $blue-color-0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 149.52%;
        text-align: center;
        display: block;
        color:  $blue-color-0;
        transition: 0.3s;

        &:hover {
          background: $blue-color-0;
          color: $white-color;
        }
      }
    }

    div a,button {
      width: 100%;
      padding: 0.5rem 1.5rem;
      color: $dark-color-0;
      text-transform: inherit;

      &:hover {
        color: $white-color;
      }

      svg {
        fill: currentColor;
        min-width: 1rem !important;
        max-width: 1rem;
        margin-right: 0.75rem;

        path {
          fill: currentColor;
        }
      }
    }
  }

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.15);
  }

  button {
    svg {
      fill: currentColor;
    }
  }
}

.input-wrapper {
  background: $white-color;
  font-weight: 600;
  font-size: $font-size-16;
  line-height: 149.52%;
  border-radius: 4px;
  color: $dark-color-0;
  padding: 0.6875rem 0.9375rem;
  width: 100%;
  max-width: 48.625rem;
  margin-right: 0.5rem;
  display: flex;
  height: 2.9375rem;
  align-items: center;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    margin-right: auto;
    font-weight: 700;
    font-size: 1.0625rem;
    line-height: 149.52%;

    &::placeholder {
      color: $gray-color-3;
      font-weight: 400;
      font-size: 1rem;
    }
  }

  .span {
    width: 0.0625rem;
    height: 1.344375rem;
    background: $gray-color-4;
    margin: 0 0.4375rem;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;

    @media (min-width: $IsTablet) {
      margin-right: 0;
    }
  }
}

.btn-info {
  padding: 0.59375rem 0.96875rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 102.02%;
  color: $dark-color-1;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  text-align: left;
  background: $white-color;
  height: 2.9375rem;
  border-radius: 3px;

  svg {
    margin-right: 0.5rem;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;

  button:first-child {
    max-width: 10.375rem;
  }

  button:last-child {
    margin-right: 0;
    max-width: 8.3125rem;
  }
}

.cart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;


  @media (min-width: $IsDesktop) {
    margin-right: 0;
    margin-left: 0.8125rem;
  }

  span {
    width: 1.3125rem;
    height: 1.3125rem;
    background: $pink-color-0;
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: $font-size-15;
    line-height: 149.52%;
    position: absolute;
    border-radius: 100%;
    bottom: calc(100% - 0.4375rem);
    left: calc(100% - 0.4375rem);
  }
}


.btn-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 0.875rem;
  padding-bottom: 1.875rem;

  @media (min-width: $IsTablet) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.border-mobile {
  height: 1px;
  background: $gray-color-5;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 0;
  margin-bottom: 0.8125rem;
  transform: translateX(-50%);
}